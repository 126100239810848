<template>
  <div class="box_sorting">
    <strong class="screen_out">검색 조건</strong>
    <ul class="list_sorting">
      <li
        :class="[
          (!isApproval && isComplete) || (isDetailPage && isComplete) ? 'item_full' : 'item_sort',
        ]"
      >
        <em class="tit_txt">계정</em>
        <div class="cont_sorting">
          <comm-opt-search
            ref="payAccount"
            method="GET"
            :url="`${this.$apiPath.APRVL_LINE_ACCONT}?useYn=Y&page=1&size=99`"
            class="w208"
            :class-list="[]"
            option-desc="계정 선택상자"
            :all-flag="true"
            :option-name="searchForm.payAccountName"
            event-name="select"
            :class="{ disabled: disabledForm }"
            @select="selectPayAccount"
          />
        </div>
      </li>
      <li
        v-if="!isApproval || isDetailPage"
        :class="[
          'item_sort',
          {
            item_right: (isApproval || !isComplete) && (!isDetailPage || !isComplete),
          },
        ]"
      >
        <!-- 결재/참조 문서가 아니거나, 상세 검색이 열려있거나 -->
        <em class="tit_txt">{{ labelSearchDate }}</em>
        <div class="cont_sorting">
          <!-- 달력 입력 -->
          <div class="box_picker" :class="{ disabled: disabledForm }">
            <flat-pickr
              v-if="temp.isShowDate"
              v-model="searchForm.draftFromDate"
              class="txt_time"
              :max-date="searchForm.draftToDate"
              :config="flatpickrConfigDayDraftFrom"
              :disabled="disabledForm"
              :placeholder="datePlaceholder"
              name="date"
              @on-change="listenToOnChangeDayDraftFrom"
            />
            <span class="ico_account ico_calendar" />
            <span class="txt_bar">~</span>
            <flat-pickr
              v-if="temp.isShowDate"
              v-model="searchForm.draftToDate"
              class="txt_time"
              :config="flatpickrConfigDayDraftTo"
              :disabled="disabledForm"
              :placeholder="datePlaceholder"
              name="date"
              @on-change="listenToOnChangeDayDraftTo"
            />
            <span class="ico_account ico_calendar" />
          </div>
        </div>
      </li>
      <li
        v-if="(!isApproval && isComplete) || (isDetailPage && isComplete)"
        class="item_sort item_right"
      >
        <em class="tit_txt">{{ labelCompleteDate }}</em>
        <div class="cont_sorting">
          <!-- 달력 입력 -->
          <div class="box_picker" :class="{ disabled: disabledForm }">
            <flat-pickr
              v-if="temp.isShowDate"
              v-model="searchForm.completeFromDate"
              class="txt_time"
              :config="flatpickrConfigDayCompleteFrom"
              :disabled="disabledForm"
              :placeholder="datePlaceholder"
              name="date"
              @on-change="listenToOnChangeDayCompleteFrom"
            />
            <span class="ico_account ico_calendar" />
            <span class="txt_bar">~</span>
            <flat-pickr
              v-if="temp.isShowDate"
              v-model="searchForm.completeToDate"
              class="txt_time"
              :config="flatpickrConfigDayCompleteTo"
              :disabled="disabledForm"
              :placeholder="datePlaceholder"
              name="date"
              @on-change="listenToOnChangeDayCompleteTo"
            />
            <span class="ico_account ico_calendar" />
          </div>
        </div>
      </li>
      <li class="item_full">
        <em class="tit_txt">{{ labelSearch }}</em>
        <div class="cont_sorting">
          <!-- 검색(sort) -->
          <div class="group_search">
            <!-- opt_comm -->
            <Radio
              v-if="isSearchTypeRadio"
              :dataList="searchSelectOptions"
              name="radioFilterbar"
              :selectedId.sync="temp.searchType"
              @onChange="selectSearchType"
            />
            <comm-opt
              v-else
              class="w208"
              :classList="['type_short']"
              :optionDesc="'검색 선택상자'"
              :optionName="searchForm.searchType.name"
              :optionList="searchSelectOptions"
              event-name="select"
              @select="selectSearchType"
            />
            <!-- opt_comm -->
            <div class="group_input">
              <k-input
                :value.sync="searchForm.searchKeyword"
                :maxLength="100"
                @keyup.enter="clickSearchButton"
              />
            </div>
            <!-- // group_input -->
          </div>
          <!-- // group_search -->
        </div>
      </li>
    </ul>
    <!-- // list_sorting -->
    <div class="wrap_btn">
      <div v-if="isDetailPage" class="pos_left">
        <!-- <a href="javascript:void(0);" class="link_fold" :class="isDetailOpen ? 'fold_on' : ''" @click.prevent="toggleSearchDetail">상세 검색<span class="ico_account"></span></a> -->
        <!-- 2019-07-10 클릭시 fold_on 클래스 토글 / item_sort 요소가 5개 이상일 때 토글할 때 동적으로 요소 추가/삭제 필요  -->
      </div>
      <button type="button" class="btn_large btn_primary" @click.prevent="clickSearchButton">
        검색
      </button>
      <a href="javascript:void(0);" class="link_reset" @click.prevent="resetSearchForm">
        <span class="ico_account" />초기화
      </a>
    </div>
  </div>
</template>

<script>
import CommOpt from "@/_approval/components/common/CommOpt";
import CommOptDept from "@/_approval/components/common/CommOptDept";
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import CommOptDeptSearch from "@/_approval/components/common/CommOptDeptSearch";
import CommOptSearch from "@/_approval/components/common/CommOptSearch";
import Radio from "@/_approval/components/common/radio/Radio";
import Input from "@/_approval/components/common/input/Input";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import { default as CommMomentMixin } from "@/_approval/mixins/common/commMoment.js";

const PLACEHOLDER = {
  PAY_ACCOUNT: "계정 선택",
};
// 초기 값
const initSearchForm = {
  payAccountName: PLACEHOLDER.PAY_ACCOUNT,
  selectedPayAccount: {},
  searchType: {},
  searchTypeList: [],
  searchKeyword: "",
  draftFromDate: "",
  draftToDate: "",
  completeFromDate: "",
  completeToDate: "",
  flatpickrConfigDayDraftFrom: {},
  flatpickrConfigDayDraftTo: {},
  flatpickrConfigDayCompleteFrom: {},
  flatpickrConfigDayCompleteTo: {},
};

export default {
  name: "StatementSearchDiv",
  components: {
    CommOpt,
    CommInpSearch,
    CommOptDept,
    CommOptDeptSearch,
    CommOptSearch,
    Radio,
    kInput: Input,
  },
  mixins: [CommFlatpickrMixin, CommLayerMixin, CommMomentMixin],
  props: {
    /**
     * 검색 필드 레이블
     */
    labelSearch: {
      type: String,
      default: "검색",
    },
    /**
     * 작성일시 필드 레이블
     */
    labelSearchDate: {
      type: String,
      default: "상신일시",
    },
    /**
     * 완료일시 필드 레이블
     */
    labelCompleteDate: {
      type: String,
      default: "승인일시",
    },
    /**
     * 상세검색 허용 페이지인가 여부 // 미사용으로 보임
     */
    isDetailPage: {
      type: Boolean,
      default: false,
    },
    /**
     * 결재문서 문서함인 경우 true // 미사용으로 보임
     */
    isApproval: {
      type: Boolean,
      default: false,
    },
    /**
     * 결재완료 (승인/반려) 문서함인 경우 true
     */
    isComplete: {
      type: Boolean,
      default: false,
    },
    /**
     * 참조문서함 여부 // 미사용으로 보임
     */
    isCc: {
      type: Boolean,
      default: false,
    },
    /**
     * 검색어 선택 타입 > 라디오 표시 여부
     * 유형 2가지만 있다고 고려. (SEARCH_TYPE_RADIO_LIST/SEARCH_TYPE_SELECTBOX_LIST)
     */
    isSearchTypeRadio: {
      type: Boolean,
      default: false,
    },
    /**
     * 검색 타입 리스트
     */
    searchSelectOptions: {
      type: Array,
      default() {
        return [
          { code: "docCid", name: "정산서번호" },
          { code: "title", name: "정산서명" },
          { code: "drft", name: "기안자" },
        ];
      },
    },
    /**
     * 검색 타입 기본값
     */
    searchSelectDefaultValue: {
      type: Object,
      default() {
        return { code: "docCid", name: "정산서번호" };
      },
    },
  },
  data() {
    return {
      searchForm: { ...initSearchForm }, // 검색 조건
      isDetailOpen: false, // 상세검색 오픈/클로즈
      draftStartDateDisabled: true,
      draftEndDateDisabled: true,
      flatpickrConfigDayDraftFrom: {},
      flatpickrConfigDayDraftTo: {},
      flatpickrConfigDayCompleteFrom: {},
      flatpickrConfigDayCompleteTo: {},
      temp: {
        searchType: "",
        isShowDate: true,
      },
    };
  },
  computed: {
    /**
     * 검색타입이 정산서번호인 경우, 비활성화
     */
    disabledForm() {
      // 210308 기획 문의 : 비활성 필요하지 않다고 하여 주석처리, 정산서 번호에서 비활성화 필요시 사용하면 됩니다.
      // return this.searchForm.searchType.code === 'docCid';
      return false;
    },
    /**
     * 검색타입이 정산서번호인 경우, 플레이스 홀더 공백
     */
    datePlaceholder() {
      // 210308 기획 문의 : 비활성 필요하지 않다고 하여 주석처리, 정산서 번호에서 비활성화 필요시 사용하면 됩니다.
      // return this.searchForm.searchType.code === 'docCid' ? '' : '날짜 선택';
      return "날짜 선택";
    },
  },
  watch: {
    /**
     * 플레이스홀더 갱신이 안되는 문제로, 컴포넌트 재랜더링
     */
    datePlaceholder() {
      // 210308 기획 문의 : 정산서 번호일 경우, 빈값 표시 없음, 정산서 번호에서 비활성화 필요시 사용하면 됩니다.
      // this.temp.isShowDate = false;
      // setTimeout(() => {
      //   this.temp.isShowDate = true;
      // }, 1);
    },
  },
  created() {
    this.flatpickrConfigDayDraftFrom = { ...this.flatpickrConfigDay };
    this.flatpickrConfigDayDraftTo = { ...this.flatpickrConfigDay };
    this.flatpickrConfigDayCompleteFrom = { ...this.flatpickrConfigDay };
    this.flatpickrConfigDayCompleteTo = { ...this.flatpickrConfigDay };
    // 찾는 폼이 비어있지 않는 경우 init을 서칭 폼으로 매핑
    if (localStorage.searchForm) {
      this.searchForm = JSON.parse(localStorage.searchForm);
    }
  },
  mounted() {
    this.initData();
    // searchForm 초기화
    this.$emit("init-search-form", this.searchForm);
  },
  methods: {
    initData() {
      if (this.searchSelectDefaultValue) {
        this.searchForm.searchType = this.searchSelectDefaultValue;
      }
      this.temp.searchType = this.searchForm.searchType.code;

      // 210308 기획 문의 : 정산서 번호일 경우, 빈값 표시 없음, 정산서 번호에서 비활성화 필요시 사용하면 됩니다.
      // if (this.searchForm.searchType.code === 'docCid') {
      //   this.searchForm.draftToDate = '';
      //   this.searchForm.draftFromDate = '';
      // } else {
      //   this.initDefaultDate();
      // }
      // this.initDefaultDate();
    },
    initDefaultDate() {
      if (!this.searchForm.draftFromDate) {
        this.searchForm.draftFromDate = this.$moment().startOf("month").format("YYYY-MM-DD");
      }
      if (!this.searchForm.draftToDate) {
        this.searchForm.draftToDate = this.$moment().endOf("month").format("YYYY-MM-DD");
      }
      if (!this.searchForm.completeFromDate) {
        this.searchForm.completeFromDate = this.$moment().startOf("month").format("YYYY-MM-DD");
      }
      if (!this.searchForm.completeToDate) {
        this.searchForm.completeToDate = this.$moment().endOf("month").format("YYYY-MM-DD");
      }
    },
    selectPayAccount(payAccount) {
      this.searchForm.payAccountName = payAccount.name;
      this.searchForm.payAccountId = payAccount.code;
      this.searchForm.selectedPayAccount = payAccount;
      // this.$emit('select-connector', this.searchForm); // 부모 함수(selectAccount)에게 넘김
    },
    selectSearchType(value) {
      const type = this.isSearchTypeRadio
        ? this.searchSelectOptions.filter((v) => v.code === value)[0]
        : value;

      // 210308 기획 문의 : 정산서 번호일 경우, 빈값 표시 없음, 정산서 번호에서 비활성화 필요시 사용하면 됩니다.
      // if (value === 'docCid') {
      //   this.searchForm.draftToDate = '';
      //   this.searchForm.draftFromDate = '';
      //   this.searchForm.selectedPayAccount = {};
      //   this.searchForm.payAccountName = PLACEHOLDER.PAY_ACCOUNT;
      // } else {
      //   this.initDefaultDate();
      // }

      this.searchForm.searchType = type;
    },
    toggleSearchDetail() {
      if (this.isDetailPage) {
        this.isDetailOpen = !this.isDetailOpen; // 상세 검색 오픈/클로징 처리
      }
    },
    clickSearchButton() {
      this.$emit("select-connector", this.searchForm); // 부모 함수(selectDocList)에게 넘김
    },
    resetSearchForm() {
      this.searchForm = { ...initSearchForm };
      this.initData();
    },
    listenToOnChangeDayDraftFrom(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigDayDraftTo.minDate = dStr;
      this.flatpickrConfigDayDraftTo = { ...this.flatpickrConfigDayDraftTo };
    },
    listenToOnChangeDayDraftTo(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigDayDraftFrom.maxDate = dStr;
      this.flatpickrConfigDayDraftFrom = {
        ...this.flatpickrConfigDayDraftFrom,
      };
    },
    listenToOnChangeDayCompleteFrom(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigDayCompleteTo.minDate = dStr;
      this.flatpickrConfigDayCompleteTo = {
        ...this.flatpickrConfigDayCompleteTo,
      };
    },
    listenToOnChangeDayCompleteTo(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigDayCompleteFrom.maxDate = dStr;
      this.flatpickrConfigDayCompleteFrom = {
        ...this.flatpickrConfigDayCompleteFrom,
      };
    },
  },
};
</script>
